import { remoteApi } from './utils/constant'
import { get,post } from './utils/request'

export default {
  storageDetails: ({ ...props }) =>
    get({
      url: remoteApi().storage_details,
      headers: {
        params: { ...props },
      },
    }),
  templatesDetails: ({ ...props }) =>
    get({
      url: remoteApi().templates_details,
      headers: {
        params: { ...props },
      },
    }),
  premiumStockDetails: () =>
    get({
      url: remoteApi().stock_details,
      headers: {
        params: {},
      },
    }),
  getQuota:(feature:string) =>
    get({
      url: remoteApi().quota_details+`${feature}`,
      headers: {
        params: {},
      },
    }),
    
  attachUsageReport:(data:any) =>
    post({
      url: remoteApi().attach_usage_report,
      data
    }),

  getLatestExport:({...props}) => 
    get({
      url:remoteApi().get_latest_project_version,
      headers:{
        params:{...props}
      }
    }),
}
