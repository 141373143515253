import { isFromMagnify } from '@/utils/magnifyEnterprise'
import { lazy, LazyExoticComponent } from 'react'
import { createBrowserRouter } from 'react-router-dom'

const Login = lazy(() => import('@/pages/login'))
const Home = lazy(() => import('@/pages/home/Home'))
const MyPieces = lazy(() => import('@/pages/home/MyPieces'))
const ProjectList = lazy(() => import('@/pages/home/ProjectList'))
const TemplateList = lazy(() => import('@/pages/home/TemplateList'))
const EditorApp = lazy(() => import('@/pages/editor'))
const Dashboard = lazy(() => import('@/pages/dashboard'))
const myVideos = lazy(() => import('@/pages/myvideos'))
const listProjects = lazy(() => import('@/pages/ListProjects'))
const listMedia = lazy(() => import('@/pages/ListMedia'))
const NotFound = lazy(()=> import('@/pages/404'))
const Templates = lazy(() => import('@/pages/templates'))
const SearchResults = lazy(() => import('@/pages/templates/search-results'))
const Onboarding = lazy(() => import('@/pages/onboarding'))
const Upgradeplan = lazy(() => import('@/pages/upgradeplan'))
const Mysubscription = lazy(() => import('@/pages/mysubscription'))
const LifetimeSubscription = lazy(() => import('@/pages/lifetimeSubscription'))
const BuySubscription = lazy(
  () => import('@/pages/purchaseSubscription/purchaseSubscription/index'),
)
const Enterprise = lazy(() => import('@/pages/enterprise'))
export interface IRoute {
  path: string
  redirect?: string
  meta?: {
    title?: string
    auth?: boolean
  }
  component?: LazyExoticComponent<any>
  children?: IRoute[]
}

/**
 * If user came from magnify, then only editor and enterprise page will be there
 */
const routes: IRoute[] = isFromMagnify()
  ? [
    {
      path: '/app/:projectId',
      meta: {
        title: 'project',
        auth: true,
      },
      component: EditorApp,
    },
    {
      path: '/enterprise',
      meta: {
        title: 'Enterprise',
        auth: false,
      },
      component: Enterprise,
    },
  ]
  : [
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/home',
      redirect: '/home/projectList',
    },
    {
      path: '/login',
      meta: {
        title: 'Log in',
        auth: false,
      },
      component: Login,
    },
    {
      path: '/app/:projectId',
      meta: {
        title: 'project',
        auth: true,
      },
      component: EditorApp,
    },
    {
      path: '/home',
      component: Home,
      children: [
        {
          path: 'myPieces',
          meta: {
            title: 'my pieces',
            auth: true,
          },
          component: MyPieces,
        },
        {
          path: 'projectList',
          meta: {
            title: 'poject list',
            auth: true,
          },
          component: ProjectList,
        },
        {
          path: 'templateList',
          meta: {
            title: 'template list',
            auth: true,
          },
          component: TemplateList,
        },
      ],
    },
    {
      path: '/dashboard',
      meta: {
        title: 'Dashboard',
        auth: false,
      },
      component: Dashboard,
    },
    {
      path: '/myvideos',
      meta: {
        title: 'myvideos',
        auth: false,
      },
      component: myVideos,
    },
    {
      path: '/templates',
      meta: {
        title: 'Templates',
        auth: false,
      },
      component: Templates,
    },
    {
      path: '/search-results',
      meta: {
        title: 'Search results',
        auth: false,
      },
      component: SearchResults,
    },
    {
      path: '/onboarding',
      meta: {
        title: 'Onboarding',
        auth: false,
      },
      component: Onboarding,
    },
    {
      path: '/upgradeplan',
      meta: {
        title: 'Upgradeplan',
        auth: false,
      },
      component: Upgradeplan,
    },

    {
      path: '/mysubscription',
      meta: {
        title: 'Mysubscription',
        auth: false,
      },
      component: Mysubscription,
    },
    {
      path: '/buy-subscription',
      meta: {
        title: 'Buy subscription',
        auth: false,
      },
      component: BuySubscription,
    },
    {
      path: '/lifetimeSubscription',
      meta: {
        title: 'Lifetime Subscription',
        auth: false,
      },
      component: LifetimeSubscription,
    },
    {
      path: '/enterprise',
      meta: {
        title: 'Enterprise',
        auth: false,
      },
      component: Enterprise,
    },
  ]

const download_routes: IRoute[] = [
    {
      path: '/',
      redirect: '/myvideos',
    },
    {
      path: '/dashboard',
      redirect: '/myvideos',
    },
    {
      path: '/myvideos',
      meta: {
        title: 'myvideos',
        auth: false,
      },
      component: listProjects,
    },
    {
      path: '/media/:id',
      meta: {
        title: 'media',
        auth: false,
      },
      component: listMedia,
    },
    {
      path: '/media',
      meta: {
        title: 'media',
        auth: false,
      },
      component: listMedia,
    },
    { 
      path: '/404', 
      meta: { 
        title: 'Not Found', 
        auth: false, 
      }, 
    component: NotFound, // Your 404 component here 
    }, 
    { path: '*', redirect: '/404', },
  ]

const router = createBrowserRouter(window.$nvsConfig.onlyDownloads? download_routes: routes, {
  basename: import.meta.env.BASE_URL,
})

export default router
